import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueScrollTo from 'vue-scrollto'

createApp(App)
    .use(store)
    .use(router)
    .use(VueScrollTo, {
        container: "body",
        duration: 300,
        easing: "ease-in",
        offset: 0,
        force: false,
        lazy: false,
        cancelable: true,
        x: false,
        y: true
    })
    .mount('#app')
