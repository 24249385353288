<template>

  <router-view/>

  <button class="up position-fixed bottom-0 end-0 translate-middle d-none" v-scroll-to="'#top'" id="back-to-up">
    <img src="@/assets/up.svg" alt="">
  </button>
</template>

<script>
window.onscroll = () => {
  toggleTopButton();
}

function toggleTopButton() {
  if (document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20) {
    document.getElementById('back-to-up').classList.remove('d-none');
  } else {
    document.getElementById('back-to-up').classList.add('d-none');
  }
}
</script>

<style>
body {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
}

.up {
  width: 55px;
  height: 55px;
  border-radius: 50px;
  border: none;
  background: transparent;
}

.up img {
  width: 100%;
  height: 100%;
}

:root {
  --white-color: #FFFFFF;
  --black-color: #1D1D1D;
  --gray-color: #494950;
}

.navbar {
  background: var(--black-color);
  height: 95px;
}

.navbar-brand img {
  width: 44px;
  height: 46px;
  margin: 0;
  padding: 0;
}

.nav-link {
  font-size: 22px;
  color: var(--white-color);
  line-height: 27px;
  letter-spacing: 0.03em;

}

a:hover {
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .wrapper__inner p {
    margin-right: 0 !important;
  }

  .wrapper__inner {
    display: flex;
    justify-content: start !important;
  }
}
</style>
